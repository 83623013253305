import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Layout } from "../../layouts/Site";
import { Component } from "../../components/Component";
import { Meadow } from "../../components/PageWidthElements";
import ErrorPage from "../404";

export default function TodoPage({ location }) {
  const { contentList } = useStaticQuery(query);

  if (!contentList)
    return <ErrorPage />;

  return <Layout title={contentList.name}>
    <Meadow amount={200} height={300} className="pt-header max-h-full">
      {contentList.contents.map(content => <Component content={content} className="max-w-4xl mx-auto" />)}
    </Meadow>
  </Layout>
}

const query = graphql`{
  contentList: contentfulSiteContentList(key: {eq: "todo"}) {
    key
    name
    contents {
      ... on ContentfulSiteContent {
        ...Content
      }
    }
  }
}`